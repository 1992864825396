<template>
  <div id="app home">
    <div v-if="!loaded || !loadedChars || !loadedHasPrompts" class="loader">
      <div class="loading loading-lg"></div>
    </div>

    <template v-else>
      <div class="scroll-top" @click="scrollToTop">(^)</div>
      <announcements :timestamp="timestamp"></announcements>
      <options></options>
      <pagination
        :options="options"
        :pagination="pagination"
        @page="page"
      ></pagination>

      <div v-if="updating" class="loader">
        <div class="loading loading-lg"></div>
      </div>

      <fandoms-list v-else :filtered="filtered"></fandoms-list>

      <pagination
        :options="options"
        :pagination="pagination"
        @page="page"
      ></pagination>
    </template>
  </div>
</template>

<script>
import Announcements from "../components/announcements.vue";
import Options from "../components/options.vue";
import FandomsList from "../components/fandoms-list";
import PromptTable from "../components/promptTable.vue";
import Pagination from "../components/pagination.vue";
import utils from "../components/utils.js";
import { latinise } from "voca";
// third party
import { filter, slice, sortBy, each, includes } from "lodash-es";
import { mapGetters } from "vuex";
import axios from "axios";
// Remove english articles from fandom names
function removeArticlesCompare(o) {
  if (!o) {
    return;
  }
  const regex = /^(the\s|a\s|an\s)/i;
  if (!o.name) {
    return o;
  }
  return o.name.toLowerCase().replace(regex, "");
}

export default {
  name: "home",
  components: {
    Announcements,
    FandomsList,
    Pagination,
    Options
  },
  beforeMount() {
    if (this.hasLetters) {
      axios
        .get(
          `https://${process.env.VUE_APP_COLLECTION_ID}-default-rtdb.firebaseio.com/letterkeys.json?shallow=true`
        )
        .then(res => {
          this.totalLetters = res.data ? Object.keys(res.data).length : 0;
        });
    }
    axios
      .get(
        `https://${process.env.VUE_APP_COLLECTION_ID}-default-rtdb.firebaseio.com/timestamp.json`
      )
      .then(res => {
        this.timestamp = res.data.timestamp;
      });
  },
  created() {
    this.updateFilter();
  },
  data() {
    return {
      totalLetters: 0,
      lastLetterUpdate: new Date(),
      tableComponent: PromptTable,
      maintenance: false,
      timestamp: null,
      showEggHelp: false,
      showHelp: false,
      // scrollPosition: 100,
      filtered: [],
      updating: true,
      sticky: false,
      letterChars: {
        fandom: "",
        characters: []
      },
      pagination: {
        first: 0,
        last: 250,
        size: 250,
        total: 0
      }
    };
  },
  computed: {
    ...mapGetters([
      "isModIn",
      "bookmarks",
      "lettermarks",
      "categories",
      "characters",
      "fandoms",
      "hasLetters",
      "hasPrompts",
      "letters",
      "loadAll",
      "loaded",
      "loadedChars",
      "loadedHasPrompts",
      "options",
      "promptmarks",
      "prompts",
      "unlock",
      "user"
    ])
  },
  watch: {
    async $route(to) {
      if (to.name === "home") {
        if (this.hasLetters) {
          axios
            .get(
              `https://tagset2023-default-rtdb.firebaseio.com/letterkeys.json?shallow=true`
            )
            .then(async res => {
              if (
                res.data &&
                Object.keys(res.data) &&
                Object.keys(res.data).length !== this.totalLetters
              ) {
                this.totalLetters = Object.keys(res.data).length;
                axios
                  .get(
                    `https://tagset2023-default-rtdb.firebaseio.com/letters.json`
                  )
                  .then(res => {
                    this.$store.commit("setLetters", res.data);
                  });
              }
            });
        }
      }
    },
    fandoms() {
      this.pagination.first = 0;
      this.pagination.last = this.pagination.size;
      this.updateFilter();
    },
    // Deep watching doesn't work
    // Reset pagination when the filters change
    "options.filter.term": function() {
      this.updateFilter(true);
    },
    "options.filter.category": function() {
      this.updateFilter(true);
    },
    "options.onlyBookmarks": function(val) {
      this.updateFilter(true);
    },
    "options.onlyLetters": function(val) {
      this.updateFilter(true);
    },
    "options.onlyPrompts": function() {
      this.updateFilter(true);
    },

    "options.loadAll": async function(val) {
      if (val && !this.loadAll.characters) {
        this.updating = true;
        await axios
          .get(
            `https://${process.env.VUE_APP_COLLECTION_ID}-default-rtdb.firebaseio.com/characters.json?orderBy="$key"`
          )
          .then(res => {
            this.$store.commit("loadAllChars", true);
            this.$store.commit("setCharacters", res.data);
          });
      } else if (!val) {
        this.pagination.last = this.pagination.size;
      }
      this.updateFilter();
    },
    loaded() {
      this.updateFilter();
    }
  },
  methods: {
    ...utils,
    updateFilter(resetPagination) {
      this.updating = true;

      if (resetPagination) {
        this.pagination.first = 0;
        this.pagination.last = this.pagination.size;
      }

      // Do not let pagination go below 0
      if (this.pagination.first < 0) {
        this.pagination.first = 0;
      }

      // When loadAll is turned on, always page all the way
      if (this.options.loadAll) {
        this.pagination.first = 0;
        this.pagination.last = this.fandoms.length;
      }

      // Remove fandoms that have no names
      let arr = filter(this.fandoms, f => {
        return f.name.length;
      });

      // Case: no filters
      // Show all fandoms, slicing by pagination
      if (
        !this.options.onlyPrompts &&
        !this.options.filter.term.length &&
        !this.options.onlyBookmarks &&
        !this.options.onlyLetters &&
        !this.options.onlyPHs &&
        !this.options.filter.category.length
      ) {
        this.filtered = slice(
          sortBy(arr, ["category", removeArticlesCompare]),
          this.pagination.first,
          this.pagination.last
        );

        this.pagination.total = arr.length;

        setTimeout(() => {
          this.updating = false;
        }, 150);

        return;
      }

      // Case: apply filters

      if (
        this.options.onlyPrompts &&
        this.hasPrompts &&
        this.hasPrompts.length
      ) {
        arr = filter(arr, o => {
          return this.hasPrompts[o[".key"]];
        });
      }

      if (this.options.onlyBookmarks) {
        const bookmarkedFandoms = [];
        each(this.bookmarks, b => {
          bookmarkedFandoms.push(b.name);
        });

        arr = filter(arr, o => {
          return includes(bookmarkedFandoms, o.name);
        });
      }

      if (this.options.onlyLetters) {
        if (!this.letters) {
          arr = [];
        } else {
          arr = filter(arr, o => {
            return this.letters[o[".key"]];
          });
        }
      }

      if (this.options.filter.category.length) {
        arr = filter(arr, o => {
          return includes(o.category, this.options.filter.category);
        });
      }

      if (this.options.filter.term.length) {
        arr = filter(arr, o => {
          return (
            latinise(o.name)
              .toLowerCase()
              .indexOf(this.options.filter.term.toLowerCase()) > -1
          );
        });
      }

      // Set pagination.last if the filtered array ends up being smaller
      // or if loadAll is turned on
      if (arr.length < this.pagination.last || this.options.loadAll) {
        this.pagination.last = arr.length;
      }

      // Slice search word and category filters by name without
      // regard for category
      if (
        this.options.filter.term.length ||
        this.options.filter.category.length
      ) {
        this.filtered = slice(
          sortBy(arr, [removeArticlesCompare]),
          this.pagination.first,
          this.pagination.last
        );

        this.pagination.total = arr.length;

        // Categories and search words return such an array of fandoms
        // with varied indices that it's a better idea to just load
        // all characters than risk 100000 requests
        if (!this.loadAll.characters) {
          axios
            .get(
              `https://${process.env.VUE_APP_COLLECTION_ID}-default-rtdb.firebaseio.com/characters.json?orderBy="$key"`
            )
            .then(res => {
              const backFill = res.data;
              const newVal = { ...this.characters, ...backFill };
              this.$store.commit("loadAllChars", true);
              this.$store.commit("setCharacters", {});
              this.$store.commit("setCharacters", newVal);
              this.updating = false;
            });
        } else {
          setTimeout(() => {
            this.updating = false;
          }, 100);
        }

        return;
      }

      // Otherwise, sort by category before slicing
      this.filtered = slice(
        sortBy(arr, ["category", removeArticlesCompare]),
        this.pagination.first,
        this.pagination.last
      );
      this.pagination.total = arr.length;

      // If too many character lists are missing, load everything
      if (
        this.filtered[this.filtered.length - 1] &&
        this.filtered[this.filtered.length - 1][".key"] - this.pagination.last >
          10 &&
        !this.loadAll.characters
      ) {
        axios
          .get(
            `https://${process.env.VUE_APP_COLLECTION_ID}-default-rtdb.firebaseio.com/characters.json?orderBy="$key"`
          )
          .then(res => {
            const backFill = res.data;
            const newVal = { ...this.characters, ...backFill };
            this.$store.commit("loadAllChars", true);
            this.$store.commit("setCharacters", {});
            this.$store.commit("setCharacters", newVal);
          });

        setTimeout(() => {
          this.updating = false;
        }, 100);
      }

      setTimeout(() => {
        this.updating = false;
      }, 100);
    },
    async page(start) {
      this.pagination.last = start + this.pagination.size;
      this.pagination.first = start;

      // load a whole bunch at once to save on i/o
      if (
        !this.characters[this.pagination.first + 50] ||
        !this.characters[this.pagination.last]
      ) {
        await axios
          .get(
            `https://${process.env.VUE_APP_COLLECTION_ID}-default-rtdb.firebaseio.com/characters.json?orderBy="$key"&startAt="${this.pagination.first}"&endAt="${this.pagination.last}"`
          )
          .then(res => {
            const backFill = res.data;
            const newVal = { ...this.characters, ...backFill };
            this.$store.commit("setCharacters", {});
            this.$store.commit("setCharacters", newVal);
          });
      }
      this.updateFilter();
      this.scrollToTop();
    },
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/app.scss";
@import "../styles/mobile.scss";
</style>
