<template>
  <section class="prompts combined">
    <a href="#" @click.prevent="hide = !hide">
      {{ hide ? `Expand Fandom (${prompts.length})` : "Collapse Fandom" }}
    </a>

    <template v-if="!hide">
      <div class="prompt" v-for="(prompt, index) in prompts" :key="index">
        <div class="user-data">
          <button
            class="bookmark btn btn-link"
            @click="togglePromptmark(prompt)"
          >
            <span v-if="hasPromptmark(prompt)" class="fas fa-heart"></span>
            <span v-else class="far fa-heart"></span>
          </button>
          <strong>
            <a
              href="#"
              @click.prevent="
                $router.push({ path: `/user/${prompt.username}` })
              "
              >{{ prompt.username }}</a
            >
          </strong>
        </div>

        <div class="requested-characters">
          <strong>Requested Characters:&nbsp;</strong>
          <span
            class="tooltip c-hand"
            data-tooltip="You must include ALL requested 
characters. If the request asks for Any, 
you must choose ONLY from the nominated 
characters.

If the optional details include 
Do Not Wants (DNWs), you MUST avoid
those in your fic."
          >
            <sup>
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </sup>
          </span>
          &nbsp;
          <span v-if="!prompt.characters">Any</span>
          <span v-else>{{ prompt.characters.join(", ") }}</span>
          <div class="divider"> </div>
            <span>{{ prompt.freeforms.join('') }}</span>
          <div class="divider"> </div>

        </div>

        <div class="prompt-text">
          <template v-if="prompt.prompt">
            <div
              v-if="
                showPrompt[index] == true || !$store.state.options.useToggles
              "
              v-html="prompt.prompt"
            ></div>
            <button
              v-if="$store.state.options.useToggles"
              class="btn btn-sm mb-2"
              @click="show(index)"
            >
              {{ !showPrompt[index] ? "Read prompt" : "Show less" }}
            </button>
          </template>

          <p v-else-if="!prompt.prompt">
            No optional details given.
            <span v-if="prompt.letter">
              However, they do have a
              <a :href="formatUrl(prompt.letter)" target="blank">letter</a>.
            </span>
          </p>
        </div>

        <div v-if="prompt.letter">
          <a :href="formatUrl(prompt.letter)" target="blank" class="letter"
            >Letter</a
          >  <span data-tooltip="If a request has HTML links in 
the optional details but no letter, 
the scraper interprets the last link
as a letter. Alas, this cannot be 
fixed due to the nature of AO3's
request page." class="tooltip c-hand"><sup><i aria-hidden="true" class="fa fa-exclamation-circle"></i></sup></span>
        </div>

        <hr class="break" />
      </div>
    </template>
  </section>
</template>

<script>
import utils from "./utils.js";
export default {
  name: "combinedview",
  props: {
    prompts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      hide: false,
      showPrompt: {},
    };
  },
  created() {
    this.prompts.forEach((element, i) => {
      this.showPrompt[i] = false;
    });
  },
  methods: {
    ...utils,
    show(index) {
      let newShow = this.showPrompt;
      newShow[index] = !newShow[index];
      this.showPrompt = {};
      this.showPrompt = newShow;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.prompt {
  margin: 0.8em 0;
  // border-bottom: 1px solid $outline-light;

  &:last-child {
    .break {
      display: none;
    }
  }
}

.bookmark {
  height: 1.3em;
}

.user-data {
  font-size: larger;

  .bookmark {
    margin-right: 0.4em;
    position: relative;
    bottom: 2px;
  }

  .letter {
    padding: 0;
    margin: 0;
    vertical-align: baseline;
  }
}

.flex {
  display: flex;
  margin-top: 1em;
}

.requested-characters {
  margin: 0.4em 0;
}

.break {
  width: 80%;
  margin: 0.8em auto;
}
</style>

<style lang="scss">
.prompt-text {
  p:first-child {
    margin-top: 0;
  }
}
</style>
