<template>
  <table class="main">
    <thead>
      <tr>
        <th class="fandom">Fandom</th>
        <th class="characters" v-if="!options.hideCharacters">
          Nominated Characters
          <span
            class="tooltip c-hand text-normal"
            data-tooltip="Nominated Characters are all 
the characters in the tagset for that 
fandom. Yuletide only allows requests 
to use characters from this list. 
No nominated characters means 
your author may write about any 
characters or the fandom universe 
in general."
          >
            <sup>
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </sup>
          </span>
        </th>
        <th
          class="letters"
          v-if="(hasLetters || isModIn) && !options.hideLetters"
        >
          Letters
          <span
            class="tooltip c-hand text-normal"
            data-tooltip="Letters submitted to the letters
post before the release of all prompts.
See the help page for more information.
Click on the Char tag to see requested
characters highlighted"
          >
            <sup>
              <i class="fa fa-question-circle" aria-hidden="true"></i>
            </sup>
          </span>
        </th>
        <th v-if="unlock" class="prompts">
          Requests
          <div>
            <small>
              Please note Requested Characters & Optional Details
              <span
                class="tooltip c-hand"
                data-tooltip="You must include ALL requested 
characters UNLESS the request has opted for
a variation, which is listed last in the 
character list. If the request asks for Any, 
you must choose ONLY from the nominated 
characters.

If the optional details include 
Do Not Wants (DNWs), you MUST avoid
those in your fic."
              >
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </span>
            </small>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(fandom, index) in filtered"
        :key="fandom['.key']"
        :class="{ odd: index % 2 !== 0 }"
      >
        <td class="fandom" data-label="Fandom">
          <router-link :to="`/fandom/${fandom['.key']}`">
            {{ fandom.name }} </router-link
          >&nbsp;
          <button
            v-if="hasLetters || isModIn"
            class="bookmark"
            @click="toggle(fandom)"
          >
            <span v-if="hasBookmark(fandom)" class="fas fa-heart"></span>
            <span v-else class="far fa-heart"></span>
          </button>
          <div class="meta">
            <span class="category meta-tag" v-if="!options.hideCategory">{{
              fandom.category.join(", ")
            }}</span>
          </div>
        </td>
        <td class="characters" v-if="!options.hideCharacters">
          <ul>
            <li
              v-for="char in getCharacters(fandom['.key'])"
              :class="{
                highlight: letterChars && letterHasChar(fandom['.key'], char),
              }"
              :key="char"
            >
              {{ char }}
            </li>
          </ul>
        </td>
        <td
          class="letters"
          v-if="(hasLetters || isModIn) && !options.hideLetters"
        >
          <ul v-if="letters">
            <li
              v-for="letter in letters[fandom['.key']]"
              :key="letter.username"
              class="letter"
            >
              <a class="user" :href="formatUrl(letter.url)" target="_blank">{{
                letter.username
              }}</a
              >&nbsp;
              <button
                class="bookmark-letter"
                @click="toggleLettermark(letter, fandom)"
              >
                <span
                  v-if="lettermarks && hasLettermark(letter, fandom)"
                  class="fas fa-heart"
                ></span>
                <span v-else class="far fa-heart"></span>
              </button>
              <div class="meta">
                <button
                  class="char-count meta-tag"
                  @click="highlightChars(letter, fandom['.key'])"
                >
                  Chars:
                  {{
                    letter.characters === undefined
                      ? "Any"
                      : letter.characters.length
                  }}
                </button>
              </div>
            </li>
          </ul>
        </td>
        <td v-if="unlock" class="prompts">
          <button
            class="btn btn-primary"
            v-if="!prompts[fandom['.key']] && hasPrompts[fandom['.key']]"
            @click="getPrompts(fandom['.key'])"
          >
            Get Prompts
          </button>
          <div
            v-if="prompts[fandom['.key']] === 'loading'"
            class="loading"
          ></div>

          <keep-alive>
            <component
              :is="tableComponent"
              :prompts="prompts[fandom['.key']]"
              v-if="
                prompts[fandom['.key']] &&
                prompts[fandom['.key']].length &&
                prompts[fandom['.key']] !== 'loading'
              "
            ></component>
            <span v-else-if="!hasPrompts[fandom['.key']]">No prompts ):</span>
          </keep-alive>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import PromptTable from "../components/promptTable.vue";
import PromptTableTwo from "../components/promptTableTwo.vue";
import utils from "../components/utils.js";
// third party
import { filter, slice, sortBy, each, includes } from "lodash-es";
import { mapGetters } from "vuex";
// Remove english articles from fandom names
function removeArticlesCompare(o) {
  if (!o) {
    return;
  }
  const regex = /^(the\s|a\s|an\s)/i;
  if (!o.name) {
    return o;
  }
  return o.name.toLowerCase().replace(regex, "");
}

export default {
  name: "fandoms-list",
  props: {
    filtered: {
      type: Array,
      default: () => [],
    },
    updating: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    switch (this.options.tableStyle) {
      case "split":
        this.tableComponent = PromptTable;
        break;
      case "combined":
        this.tableComponent = PromptTableTwo;
        break;
      default:
        this.tableComponent = PromptTable;
        break;
    }
  },
  watch: {
    "options.tableStyle": function (val) {
      switch (val) {
        case "split":
          this.tableComponent = PromptTable;
          break;
        case "combined":
          this.tableComponent = PromptTableTwo;
          break;
        default:
          this.tableComponent = PromptTable;
          break;
      }
    },
  },
  data() {
    return {
      tableComponent: PromptTable,
      letterChars: {
        fandom: "",
        characters: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "isModIn",
      "bookmarks",
      "lettermarks",
      "categories",
      "characters",
      "hasLetters",
      "hasPrompts",
      "letters",
      "loaded",
      "options",
      "promptmarks",
      "prompts",
      "unlock",
      "user",
    ]),
  },
  methods: {
    ...utils,
  },
};
</script>
