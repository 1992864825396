<template>
  <div class="toast">
    <p v-if="!hasLetters">
      The tagset is <strong>NOT FINAL</strong> and is currently undergoing
      corrections. This app was updated at
      {{ new Date(timestamp).toLocaleString() }} (local time) and
      <strong>may be out of sync with the official tagset</strong>. This is why
      bookmarking is currently disabled :) Check the official tagset
      <a href="https://archiveofourown.org/tag_sets/17092">here</a>! If you think
      something looks wrong, read the corrections post and report the issue
      <a href="https://yuletide-admin.dreamwidth.org/105829.html">here!</a>
    </p>

    <p v-if="unlock">
      <span v-if="promptsLink.length">
        Requests are also available as a
        <a :href="promptsLink">Google sheet</a>. Please comment
        <a href="https://yuletide-admin.dreamwidth.org" target="_blank"
          >on the admin comm</a
        >
        to report any bugs.
      </span>
      Fandoms with prompts are fandoms with AO3 signups. Fandoms with
      letters/the Letters column are fandoms with user submitted letters, some
      of which may be pinch hitters without a signup.
    </p>
    <p v-if="!hasLetters">Bookmarking will be enabled when signups begin.</p>

    <p v-if="hasLetters">
      If you lose your letter edit key, please reach out to a mod on
      <a href="https://discord.gg/amR43JuT" target="blank">Discord</a> or on the
      <a href="https://yuletide.dreamwidth.org/304485.html">Letters Post</a>.

      <span v-if="!unlock">
        Remember that adding a letter is NOT equivalent to
        <a
          href="https://archiveofourown.org/collections/yuletide2023/signups/new"
          target="blank"
          >signing up</a
        >!

        <span v-if="lettersLink.length">
          Letters are also available as a
          <a :href="lettersLink">Google spreadsheet</a>, updated from this app
          every 15 minutes.
        </span></span
      >
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    timestamp: {
      type: String
    }
  },
  data() {
    return {
      promptsLink: process.env.VUE_APP_PROMPTS_GDOC,
      lettersLink: process.env.VUE_APP_LETTERS_GDOC
    };
  },
  computed: {
    ...mapGetters(["hasLetters", "unlock"])
  }
};
</script>